export const GET_CONFIGURATION_ENDPOINT = '/unAuth/mobile/getMobileConfiguration'
export const GET_ORGANIZATION_DATA_ENDPOINT = '/unAuth/organization/data'
export const GET_COUNTRIES_ENDPOINT = 'unAuth/organization/getDestinationCountries'
export const GET_PURPOSES_OF_TRANSFER_ENDPOINT = '/organization/getTransferPurpose/IL'
export const GET_SOURCE_OF_FUNDS_ENDPOINT = '/organization/getSourceOfFunds/IL'

export const GET_OCCUPATIONS_ENDPOINT = '/organization/occupations'
export const GET_RELATION_SHIPS = '/organization/getRecipientRelationship/IL'

export const GET_DOC_TYPES_ENDPOINT = '/user/docTypes'
export const GET_ID_DOC_TYPES_ENDPOINT = '/user/idTypes'

export const whatsAppNumber = '+972535505044'
export const emailSupport = 'Support@stbunion.com'
export const phoneSupport = '+97239444407'
export const phoneSupport2 = '+97239444406'
export const website = 'http://stbunion.com/'
export const facebook = 'facebook'
export const address = 'David Senior 3, Rishon le Zion, Israel'
export const shortAddress = 'Rishon le Zion, David Senior 3,'
export const privacyPolicyUrl = (lng: string) => (lng === 'ru' || lng === 'he')
  ? `https://stbunion.com/${lng}/privacy-policy`
  : 'https://stbunion.com/privacy-policy'

export const termsAndConditionsUrl = (lng: string) => (lng === 'ru' || lng === 'he')
  ? `https://stbunion.com/${lng}/terms-of-use`
  : 'https://stbunion.com/terms-of-use'

export const cookiePolicyUrl = (lng: string) => (lng === 'ru' || lng === 'he')
  ? `https://stbunion.com/${lng}/cookie-policy`
  : 'https://stbunion.com/cookie-policy'

export const email = 'support@stbunion.com'

export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.stb.v2'
export const APP_STORE_LINK = 'https://apps.apple.com/ru/app/stb-union/id1448266502'

export const FIREBASE_DEEP_LINK = 'https://stbunion.page.link/yzNn'

export const INSTAGRAM_LINK = 'https://www.instagram.com/stbunion/?igshid=1obg9gw1glirx'
export const FACEBOOK_LINK = 'https://www.facebook.com/stb.union.financial.services'

export const FAQ: { order: number; question: string; answer: string | Array<{ text: string; order: number }> }[] = [
  {
    order: 1,
    question: 'how_send_by_card',
    answer: [
      {order: 1, text: 'complete_registration_process'},
      {order: 2, text: 'fill_recipient_data'},
      {order: 3, text: 'check_entered_data'},
      {order: 4, text: 'use_israel_credit_card'},
    ],
  },
  {
    order: 2,
    question: 'find_out_transfer_commission',
    answer: 'transfer_commission_description',
  },
  {
    order: 3,
    question: 'what_currency_sent_transfer',
    answer: 'currency_sent_transfer_description',
  },
  {
    order: 4,
    question: 'where_get_transfer',
    answer: 'where_get_transfer_description',
  },
  {
    order: 5,
    question: 'incorrect_recipient_data',
    answer: 'incorrect_recipient_data',
  },
  {
    order: 6,
    question: 'not_country_on_list',
    answer: 'not_country_on_list',
  },
  {
    order: 7,
    question: 'from_which_card',
    answer: 'from_which_card',
  },
  {
    order: 8,
    question: 'is_there_limit_for_card',
    answer: 'is_there_limit_for_card',
  },
  {
    order: 9,
    question: 'how_find_transfer_status',
    answer: 'how_find_transfer_status',
  },
  {
    order: 10,
    question: 'how_change_recipient_data',
    answer: 'how_change_recipient_data',
  },
  {
    order: 11,
    question: 'what_to_do_if_compliance_review',
    answer: 'what_to_do_if_compliance_review',
  },
  {
    order: 12,
    question: 'how_return_transfer',
    answer: 'how_return_transfer',
  },
  {
    order: 13,
    question: 'hidden_fees_for_transfer',
    answer: 'hidden_fees_for_transfer',
  },
  {
    order: 14,
    question: 'transfer_not_go_through',
    answer: 'transfer_not_go_through',
  },
  {
    order: 15,
    question: 'quickly_repeat',
    answer: 'quickly_repeat',
  },
  {
    order: 16,
    question: 'how_send_swift',
    answer: 'how_send_swift',
  },
]
