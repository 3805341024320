import React, { memo, useCallback } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'redux/app.hooks'
import { openModal } from 'redux/reducers/ui.reducer'
import DownloadApp from 'features/ModalContent/DownloadApp'

import useStyles from './styles'

const WarningHeader = () => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  const handleOpen = useCallback(() => {
    dispatch(openModal({
      type: DownloadApp,
      paperClass: 'narrow-modal',
    }))
  }, []);

  return (
    <AppBar
      color="default"
      position="fixed"
      sx={{ boxShadow: 'none' }}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <Typography variant={"subtitle2"} sx={{ color: 'grey.500' }}>
          {t('info:web_no_longer')}
        </Typography>
        <Box sx={{cursor: 'pointer', minWidth: 164}} onClick={handleOpen}>
          <Typography variant={"subtitle2"} sx={{ fontWeight: 700, color: 'grey.500', textDecoration: 'underline' }}>
            {t('info:download_app')}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default memo(WarningHeader)
