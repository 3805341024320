import React, { FC, useCallback } from 'react'
import { Grid, Box, Link, Typography, CardMedia, useMediaQuery } from '@mui/material'
import classnames from 'classnames'
import Icon from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'redux/app.hooks'
import { openModal } from 'redux/reducers/ui.reducer'
import Requisites from 'features/ModalContent/BankRequsities'
import {
  shortAddress,
  phoneSupport,
  emailSupport,
  address,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  FIREBASE_DEEP_LINK,
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK,
} from 'constants/configuration.constants'

import useStyles from './styles'
import QRCode from "react-qr-code";

interface IFooterProps {
  sideBarOpen: boolean
}

const TopFooter: FC<IFooterProps> = ({ sideBarOpen }) => {
  const { classes } = useStyles()

  const { t } = useTranslation('info')
  const matches = useMediaQuery('(min-width:1280px)')
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const showRequisities = useCallback(() => {
    dispatch(openModal({ type: Requisites, paperClass: 'wide-modal' }))
  }, [])

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item sm={2}>
          <Typography variant="body2" className={classes.sectionTitle}>
            {t('helpful')}
          </Typography>
          <Box pt={2}>
            <Link variant="body1" className={classes.linkPage} onClick={() => navigate('/frequency-questions')}
                  underline={'none'} href={'#'}>
              {t('settings:faq')}
            </Link>
          </Box>
          <Box pt={1.5}>
            <Link variant="body1" className={classes.linkPage} onClick={() => navigate('/transfer-guide')}
                  underline={'none'} href={'#'}>
              {t('main:guide')}
            </Link>
          </Box>
          {/*<Box pt={1.5}>*/}
          {/*  <Link variant="body1" className={classes.linkPage} onClick={() => navigate('/offices')} underline={'none'}*/}
          {/*        href={'#'}>*/}
          {/*    {t('main:our_offices')}*/}
          {/*  </Link>*/}
          {/*</Box>*/}
          <Box pt={1.5}>
            <Link variant="body1" className={classes.linkPage} onClick={showRequisities} underline={'none'} href={'#'}>
              {t('info:requisites')}
            </Link>
          </Box>
        </Grid>
        <Grid item sm={3}>
          <Typography variant="body2" className={classes.sectionTitle}>
            {t('settings:contacts')}
          </Typography>
          <Box pt={2}>
            <Typography variant="body1" color="textPrimary">
              {matches ? address : shortAddress}
            </Typography>

            {/*{!matches && (*/}
            {/*  <Box pt={1.5}>*/}
            {/*    <Typography variant="body1" color="textPrimary">*/}
            {/*      Israel*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}
            {/*)}*/}
            <Box pt={1}>
              <Typography variant="body1" color="textPrimary">
                {phoneSupport}
              </Typography>
            </Box>
            <Box pt={1}>
              <Typography variant="body1" color="textPrimary">
                {emailSupport}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={2} pl={1}>
          <Typography variant="body2" align={"center"} className={classes.sectionTitle}>
            {t('we_networks')}
          </Typography>
          <Box pt={2} display="flex" alignItems="center" justifyContent={"center"} gap={2.5}>
            <Box className={classes.socialWrapper}>
              <Link className={classnames(classes.socialWrapper, classes.socialIconWrap, classes.fb)} href={FACEBOOK_LINK}
                    target="_blank">
                <Icon icon="facebook" color="#fff" size={10}/>
              </Link>
            </Box>
            <Box className={classes.socialWrapper}>
              <Link className={classnames(classes.socialWrapper, classes.socialIconWrap)} href={INSTAGRAM_LINK}
                    target="_blank">
                <CardMedia component="img" src={require('assets/images/instagram_logo.png')} width={24}/>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={5}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Box>
              <Typography variant="body2" className={classnames(classes.sectionTitle)}>
                {t('download_app')}
              </Typography>
              <Box pt={2} className={classes.storeSection}>
                <Link href={APP_STORE_LINK} target="_blank">
                  <CardMedia image={require('assets/images/app_store.png')} className={classes.storeImage}/>
                </Link>
                <Link href={GOOGLE_PLAY_LINK} target="_blank">
                  <CardMedia image={require('assets/images/google_play.png')} className={classnames(classes.storeImage)}/>
                </Link>
                <Box className={classnames("box-flex-center", classes.qrCode)}>
                  <QRCode value={FIREBASE_DEEP_LINK} size={106}/>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TopFooter
