import React, { useState, useEffect, useRef } from 'react'
import { Box, CardMedia, useMediaQuery } from '@mui/material'
import { useIntercom } from 'react-use-intercom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useAppSelector, useAppDispatch } from 'redux/app.hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { setNavigate, openModal } from 'redux/reducers/ui.reducer'
import UnauthLayout from 'unauthPages/Layout'
import AuthLayout from 'pages/Layout'
import AdaptiveWarning from 'unauthPages/AdaptiveWarning'
import { IUserMeta } from 'model/user.model'
import SoonNotEnabled from 'features/ModalContent/SoonNotEnabled'

const Router = () => {
  const matchWidth = useMediaQuery('(min-width:1360px)');

  const [waiting, setWaiting] = useState(true)

  const { i18n } = useTranslation()

  const location = useLocation()
  const navigate = useNavigate()

  const { boot, update } = useIntercom()

  const timer = useRef<any>()

  const dispatch = useAppDispatch()
  const { userId, token, documents, userMeta } = useAppSelector(({ user }) => user)

  useEffect(() => {
    timer.current = setTimeout(() => {
      setWaiting(false)
      boot({
        customLauncherSelector: '#intercom-button',
        hideDefaultLauncher: true,
        company: {
          companyId: '3',
          name: 'WEB-PORTAL',
        },
      })
    }, 3000)

    dispatch(setNavigate({ navigate }))

    return () => {
      clearTimeout(timer.current);
    };
  }, [])

  useEffect(() => {
    if (!!token) {
      if (waiting) {
        clearTimeout(timer.current)
        setWaiting(false)
        boot({
          customLauncherSelector: '#intercom-button',
          hideDefaultLauncher: true,
          company: {
            companyId: '3',
            name: 'WEB-PORTAL',
          },
        });
      }

      const secret = process.env.REACT_APP_INTERCOM_SECRET
      const enc = new TextEncoder()
      // @ts-ignore
      const crypto = window.crypto || window.msCrypto
      crypto.subtle
        .importKey(
          'raw', // raw format of the key - should be Uint8Array
          enc.encode(secret),
          {
            // algorithm details
            name: 'HMAC',
            hash: {name: 'SHA-256'},
          },
          false, // export = false
          ['sign', 'verify'] // what this key can do
        )
        .then((key) => {
          window.crypto.subtle.sign('HMAC', key, enc.encode(userId)).then((signature) => {
            const b = new Uint8Array(signature)
            const userHash = Array.prototype.map.call(b, (x) => ('00' + x.toString(16)).slice(-2)).join('')
            update({
              userId,
              name: `${(userMeta as IUserMeta).firstName} ${(userMeta as IUserMeta).lastName}`,
              email: (userMeta as IUserMeta).email,
              phone: (userMeta as IUserMeta).phone,
              unsubscribedFromEmails: true,
              userHash,
              company: {
                companyId: '3',
                name: 'WEB-PORTAL',
              },
              customAttributes: {
                dateOfBirth: (userMeta as IUserMeta).dateOfBirth,
                age: moment().diff(userMeta.dateOfBirth, 'years'),
                gender: userMeta.gender,
                emailVerified: !userMeta.emailVerified ? 'FALSE' : 'TRUE',
                city: userMeta.city,
                complianceStatus: userMeta.complianceStatus,
                idType: `${userMeta.idType || ''} ${userMeta.idIssueByCountry || ''}`,
                idNumber: `${userMeta.idSerialNumber || ''}${userMeta.idNumber}`,
                idExpiryDate: userMeta.idExpiryDate,
                language: i18n.language,
              }
            })
          })
        })
      dispatch(openModal({
        type: SoonNotEnabled,
      }))
    }
  }, [token])

  if (!matchWidth) {
    return <AdaptiveWarning/>
  }

  if (waiting) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CardMedia
          component={"img"}
          src={require('assets/images/logo-gif.gif')}
          sx={{ width: 360 }}
        />
      </Box>
    )
  }

  if (!!token && location.pathname !== '/check-profile') {
    return <AuthLayout/>
  }

  return <UnauthLayout checkProfile={!!token}/>
}

export default Router
