import { call, takeEvery, take, put, select, fork, all, delay, cancelled, ForkEffect } from 'redux-saga/effects'
import { eventChannel, END, Task, EventChannel } from 'redux-saga'
import { PayloadAction } from '@reduxjs/toolkit'
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { Unsubscribe, MessagePayload } from 'firebase/messaging'
import {
  ILoginRequest,
  ISendNewPasswordRequest,
  IDocument,
  UPLOAD_TYPES,
  IUser,
  IChangeSettingsRequest,
  INotification,
  INotificationsListRequest,
  TNotificationsData,
} from 'model/user.model'
import { setAuthToken, removeAuthToken, actionsHandler } from 'services/apiServices/api'
import notificationService from 'services/firebase.service'
import * as userService from 'services/apiServices/user.service'
import { SUCCESS, REQUEST, FAIL } from 'constants/global.constants'
import { GET_CONTACT_OPTIONS } from 'constants/user.constants'
import LoginConfirmation from 'features/ModalContent/LoginConfirmation'
import SendVerification from 'features/ModalContent/SendVerification'
import ChangePhoneSuccess from 'features/ModalContent/ChangePhoneSuccess'
import ConfirmPhone from 'features/ModalContent/ConfirmPhone'
import ActionsRequired from 'features/ModalContent/ActionsRequired'
import ActionSuccess from 'features/ModalContent/ActionSuccess'
import MismatchData from 'features/ModalContent/MismatchData'
import DossierSuccess from 'features/ModalContent/DossierSuccess'
import BlockedAccount from 'features/ModalContent/BlockedAccount'
import {
  loginActions,
  setSignupDetails,
  sendNewPasswordActions,
  editUserActions,
  setVerificationTimer,
  checkAccount,
  sendVerificationCode,
  setLoginData,
  getLinkResetPassword,
  signupActions,
  setUserDocuments,
  updateDocuments,
  clearUploadFiles,
  logout,
  sendEmailMessage,
  getContactOptionsActions,
  changeSettings,
  setContactOptions,
  getNotificationsRequest,
  getNotificationsFail,
  getNotificationsSuccess,
  setNotificationsQuantity,
  deleteNotificationsActions,
  validateNewPhoneActions,
  approveNewPhoneActions,
  cancelChangePhoneActions,
  createSessionChangePhoneActions,
} from '../reducers/user.reducer'
import { openToast, openModal, closeModal, openSpinner, closeSpinner } from '../reducers/ui.reducer'
import { getRecipientsRequest } from '../reducers/recipient.reducer'
import { getFavoriteRequest, getTransactionsRequest } from '../reducers/transaction.reducer'
import { getOperatorsRequest, getExchangeRatesRequest } from '../reducers/configuration.reducer'
import { getWalletsRequest, getCardProductsRequest, getIssueCardsRequest, getCloseCardsRequest } from '../reducers/wallet.reducer'
import { getRelationshipsSaga, getBankAccountSaga } from './configuration.sagas'
import i18n from 'locales/i18n'

const organizationId = process.env.REACT_APP_ORGANIZATION_ID

let timerTask: Task

function createNotificationChannel() {
  return eventChannel((emitter) => {
    const workerListener = ({ data }: any) => {
      emitter(data)
    }

    const onMessageListener = (payload: MessagePayload) => {
      emitter({
        ...payload,
        type: 'newNotification',
      })
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', workerListener)
    }

    let messagingUnsubscribe: Unsubscribe | null = null

    setTimeout(() => {
      const messageListener = notificationService.getMessaging()
      messagingUnsubscribe = !messageListener ? null : messageListener(onMessageListener)
    }, 700)

    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', workerListener)
      }
      if (!!messagingUnsubscribe) {
        messagingUnsubscribe()
      }
    }
  })
}

function* watchServiceWorker() {
  const notificationChannel: EventChannel<any> = yield call(createNotificationChannel)

  while (true) {
    const data: TNotificationsData = yield take(notificationChannel)

    switch (data.type) {
      case 'notificationsQty':
        if (data.error) {
          console.log(data.error)
          return
        }
        yield put(setNotificationsQuantity({ size: data.size, unread: data.unread }))
        yield put(getNotificationsRequest({ page: 0 }))
        break
      case 'notificationsList':
        if (data.error) {
          console.log(data.error)
          yield put(getNotificationsFail())
        } else {
          const { notifications } = yield select(({ user }) => user)
          const result: INotification[] = data.page === 0 || !!data.filter
            ? (data.notifications || [])
            : [...notifications, ...(data.notifications || [])]
          yield put(getNotificationsSuccess(result))
        }
        break
      case 'newNotification':
        const { notificationQuantity, unreadNotifications } = yield select(({ user }) => user)
        yield put(setNotificationsQuantity({
          size: notificationQuantity + 1,
          unread: unreadNotifications + 1,
        }))
        yield put(getNotificationsRequest({ page: 0 }))
        break
    }
  }
}

export const getPhoneNumberObject = (phoneNumber: string | undefined, country: string) => {
  const code = country === 'KV'
    ? 'XK'
    : country === 'AN' ? 'BQ' : country
  return parsePhoneNumber(phoneNumber || '', code as CountryCode)
}

export const countdown = (sec: number, interval: number = 1000) => {
  let counter = sec
  return eventChannel((emitter) => {
    const intervalId = setInterval(() => {
      counter -= 1
      if (counter >= 0) {
        emitter(counter)
      } else {
        emitter(END)
      }
    }, interval)

    return () => {
      clearInterval(intervalId)
    }
  })
}

export function* verificationTimerSaga() {
  const countChannel: EventChannel<any> = yield call(countdown, 60)
  yield put(setVerificationTimer(60))
  try {
    while (true) {
      const seconds: number = yield take(countChannel)
      yield put(setVerificationTimer(seconds))
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      countChannel.close()
      yield put(setVerificationTimer(0))
    }
  }
}

export function* loginSaga({ payload }: PayloadAction<ILoginRequest>): Generator<any, any, any> {
  try {
    const loginData: Partial<ILoginRequest> = yield select(({ user }) => user.loginData)
    const { openModal: isOpen, navigation } = yield select(({ ui }) => ui)

    if (
      timerTask &&
      timerTask.isRunning() &&
      !isOpen &&
      loginData?.password === payload.password &&
      loginData?.phoneNumber === payload.phoneNumber &&
      loginData?.idNumber === payload.idNumber
    ) {
      yield put(openModal({ type: LoginConfirmation }))
      yield put(loginActions.FAIL('WAITING CONFIRMATION CODE'))
      return
    }

    const phoneNumber = getPhoneNumberObject(payload.phoneNumber, payload.phoneCountry || 'IL')

    const { data } = yield call(userService.checkUserExist, {
      idNumber: payload.idNumber,
      phoneCountry: payload.phoneCountry,
      phoneNumber: phoneNumber.number as string,
      origin: 'WEB',
    })

    if (data.result === 'DATA_MISMATCH') {
      yield put(loginActions.FAIL('DATA_MISMATCH'))
      yield put(openModal({
        type: MismatchData,
        paperClass: 'wide-modal',
      }))
      yield put(setLoginData(payload))
      timerTask && timerTask.isRunning() && timerTask.cancel()
      return
    }

    if (data.result === 'NOT_FOUND') {
      yield put(loginActions.FAIL('NOT_FOUND'))
      timerTask && timerTask.isRunning() && timerTask.cancel()
      yield put(setSignupDetails(payload))
      navigation.navigate('/install-app')
      return
    }

    yield put(setLoginData(payload))
    removeAuthToken()

    const loginPayload: Partial<ILoginRequest> = {
      password: payload.password,
      organizationId,
      origin: 'WEB',
    }

    if (payload.phoneNumber) {
      loginPayload.phoneNumber = phoneNumber.number as string
    } else {
      loginPayload.email = payload.email
    }
    if (payload.verificationCode) {
      loginPayload.verificationCode = payload.verificationCode
    }

    const { data: loginResponse } = yield call(userService.loginUser, loginPayload)

    if (loginResponse.status === 'SUCCESS' && !loginResponse.token) {
      timerTask && timerTask.isRunning() && timerTask.cancel()
      yield put(loginActions[FAIL]('Waiting for confirmation'))
      yield put(openModal({ type: LoginConfirmation }))
      timerTask = yield fork(verificationTimerSaga)
      return loginResponse
    }

    if (loginResponse.statusCode === 403 && !payload.verificationCode) {
      throw new Error('validation:enter_valid_phone_email_password')
    }
    if (loginResponse.statusCode === 403 && !!payload.verificationCode) {
      throw new Error('validation:wrong_verification_code')
    }

    const { token, userId, userData } = loginResponse
    const { userPhoneLoggerData, organizationData, ...userMeta } = userData

    if (userMeta?.complianceStatus === 'FAILED') {
      yield put(loginActions[FAIL]('Account is blocked'))
      yield put(openModal({
        type: BlockedAccount,
        paperClass: 'wide-modal',
      }))
      return
    }

    setAuthToken(token)
    sessionStorage.setItem('@AuthToken', token)
    timerTask && timerTask.isRunning() && timerTask.cancel()

    yield* uploadDocuments(userId)

    const loginPhone = getPhoneNumberObject(userMeta.phone, userMeta.country)

    yield put(
      loginActions[SUCCESS]({
        token,
        userId,
        userMeta: {
          ...userMeta,
          phoneNumber: loginPhone.nationalNumber,
          phoneCountry: userMeta.country,
        },
      }),
    )

    yield put(approveNewPhoneActions[SUCCESS](userPhoneLoggerData || {}))

    yield call(userService.sendUserActivity, {
      phoneNumber: userMeta.phone,
      timeSend: moment.utc().format(),
      deviceId: navigator.userAgent,
    })
  } catch (err) {
    yield put(loginActions[FAIL](err.message || err.toString()))
    yield put(openToast({ type: 'error', toastMessage: err.message }))
  }
}

function* successLoginSaga({ payload }: PayloadAction<IUser>) {
  const documents = yield* getUserDocumentsSaga()
  if (!!documents?.AVATAR) {
    yield call(getContentDocuments, { AVATAR: documents.AVATAR })
  }
  yield put(getWalletsRequest())
  yield put(getExchangeRatesRequest({}))
  yield put(getRecipientsRequest())
  yield put(getFavoriteRequest())

  yield delay(30)

  yield put(getOperatorsRequest())
  yield put(getCardProductsRequest())
  yield put(getIssueCardsRequest({ afterCreate: false }))
  yield put(getCloseCardsRequest())

  const { navigation } = yield select(({ ui }) => ui)

  if (payload.startTime) {
    const duration = Date.now() - payload.startTime
    if (duration < 2500) {
      yield delay(2500 - duration)
    }
  }

  if (!documents?.SELFIE || (!documents?.ID && !documents?.PASSPORT && !documents?.DRIVING_LICENCE) || !payload.userMeta.email) {
    yield delay(50)
    navigation.navigate('/check-profile')
  } else {
    const last = sessionStorage.getItem('last-screen')
    navigation.navigate(last || '/dashboard')
  }

  yield* getRelationshipsSaga()
  yield* getBankAccountSaga()
  yield notificationsSaga(payload.userId || '')

  if (payload.userMeta.complianceStatus === 'ACTION_REQUIRED' && (payload.userMeta.actionRequiredReasons || []).length > 0 && !!documents?.SELFIE) {
    yield put(openModal({
      type: ActionsRequired,
      paperClass: 'wide-modal',
    }))
  }
}

function* notificationsSaga(userId: string) {
  try {
    const savedToken = localStorage.getItem('notificationToken')
    const firebaseAuthToken = notificationService.getFirebaseAuthToken()
    const token = notificationService.getNotificationToken()
    if (!!token && (!savedToken || savedToken !== token)) {
      const data = {
        userId,
        token,
        type: 'web',
      }
      yield call(userService.setDeviceToken, data, firebaseAuthToken)
      localStorage.setItem('notificationToken', token)
    }
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'getNotificationsQuantity',
        userId,
      })
    } else {
      const { data: qtyData } = yield call(userService.getNotificationsQuantity, userId, firebaseAuthToken)
      yield put(setNotificationsQuantity(qtyData))
      yield delay(100)
      yield put(getNotificationsRequest({ page: 0 }))
    }
  } catch (err) {
    console.log(err)
  }
}

export function* getNotificationsSaga({ payload }: PayloadAction<INotificationsListRequest>) {
  try {
    const { notifications, notificationQuantity, userId } = yield select(({ user }) => user)
    if (!userId) {
      yield put(getNotificationsFail())
      return
    }

    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        ...payload,
        userId,
        type: 'getNotificationsList',
      })
    } else {
      const authToken = notificationService.getFirebaseAuthToken()
      const { data } = yield call(userService.getListNotifications, { ...payload, userId }, authToken)
      yield put(getNotificationsSuccess(payload.page === 0 || !!payload.filter
        ? data
        : [...notifications, ...data],
      ))
    }
  } catch (err) {
    console.log(err)
    yield put(getNotificationsFail())
  }
}

export function* deleteNotificationsSaga({ payload }: PayloadAction<string[]>) {
  try {
    const { userId } = yield select(({ user }) => user)
    let query = `?userId=${userId}&`
    payload.forEach((id, idx) => idx === payload.length - 1
      ? query += `ids[]=${id}`
      : query += `ids[]=${id}&`,
    )
    const firebaseAuthToken = notificationService.getFirebaseAuthToken()

    yield call(userService.deleteNotifications, query, firebaseAuthToken)

    const { notifications, notificationQuantity } = yield select((state) => state.user)
    const updatedList = notifications.filter((note: INotification) => !payload.includes(note.id))
    yield put(getNotificationsSuccess(updatedList))
    yield put(setNotificationsQuantity({ size: notificationQuantity - payload.length }))
    userService.getListNotifications.cache
    && userService.getListNotifications.cache.clear && userService.getListNotifications.cache.clear()
  } catch (err) {
    yield put(deleteNotificationsActions.FAIL())
  }
}

export function* checkAccountSaga({ payload }: PayloadAction<ILoginRequest>) {
  try {
    timerTask && timerTask.isRunning() && timerTask.cancel()

    const phoneNumber = getPhoneNumberObject(payload.phoneNumber, payload.phoneCountry || 'IL')

    const { data } = yield call(userService.checkUserExist, {
      idNumber: payload.idNumber,
      phoneCountry: payload.phoneCountry,
      phoneNumber: phoneNumber.number as string,
      origin: 'WEB',
    })

    const { navigation } = yield select(({ ui }) => ui)

    if (data.result === 'DATA_MISMATCH') {
      yield put(setSignupDetails(payload))
      // navigation.navigate('/signup/support')
      return
    }

    if (data.result === 'NOT_FOUND') {
      yield put(setSignupDetails(payload))
      navigation.navigate('/signup/security')
      return
    }

    if (data.result === 'EXISTS') {
      yield put(getLinkResetPassword({
        phoneNumber: payload.phoneNumber || '',
        phoneCountry: payload.phoneCountry || 'IL',
      }))
      return
    }
  } catch (err) {
    yield put(openToast({ type: 'error', toastMessage: err.message || err.toString() }))
  }
}

export function* sendVerificationCodeSaga() {
  const { phoneNumber, phoneCountry } = yield select(({ user }) => user.signup)
  const { openModal: isOpenModal } = yield select(({ ui }) => ui)

  if (timerTask && timerTask.isRunning() && !isOpenModal) {
    yield put(openModal({ type: SendVerification }))
    return
  }

  const phone = getPhoneNumberObject(phoneNumber, phoneCountry)
  const verificationPayload = {
    country: phoneCountry,
    phoneNumber: phone.number as string,
    organizationId,
  }
  yield call(userService.sendSignupVerification, verificationPayload)
  timerTask = yield fork(verificationTimerSaga)
  yield put(openModal({ type: SendVerification }))
  return
}

export function* sendNewPasswordSaga({ payload }: PayloadAction<Partial<ISendNewPasswordRequest>>) {
  try {
    const { phoneNumber, phoneCountry } = yield select(({ user }) => user.loginData)
    const phone = getPhoneNumberObject(phoneNumber, phoneCountry)
    const { data } = yield call(userService.sendResetPassword, {
      token: payload.token,
      password: payload.password,
      phoneNumber: phone?.number || '',
    })
    if (data.status !== 'SUCCESS') {
      throw new Error(data.message)
    }
    yield put(openToast({ type: 'success', toastMessage: 'signin:password_reset' }))
    yield put(sendNewPasswordActions[SUCCESS]())
    const { navigation } = yield select(({ ui }) => ui)
    navigation.navigate('/start')
    timerTask && timerTask.isRunning() && timerTask.cancel()
  } catch (err) {
    yield put(sendNewPasswordActions[FAIL]())
    yield put(openToast({
      type: 'error',
      toastMessage: err.message === 'Token is not alive'
        ? 'forgot_password:wrong_temp_password'
        : err.message,
    }))
  }
}

export function* getLinkResetPasswordSaga({ payload }: PayloadAction<{ phoneNumber: string, phoneCountry: string }>) {
  try {
    timerTask && timerTask.isRunning() && timerTask.cancel()
    const phone = getPhoneNumberObject(payload.phoneNumber, payload.phoneCountry)
    yield userService.getResetPasswordLink({
      phoneNumber: phone?.number || '',
      origin: 'WEB',
    })
    timerTask = yield fork(verificationTimerSaga)
    yield put(setLoginData({ ...payload }))
    const { navigation } = yield select(({ ui }) => ui)
    navigation.navigate('/restore-password')
  } catch (err) {
    console.log(err)
  }
}

export function* signUpSaga() {
  try {
    timerTask && timerTask.isRunning() && timerTask.cancel()
    const {
      phoneCountry,
      phoneNumber,
      confirmPassword,
      agreed,
      permissionPhone,
      permissionPost,
      permissionSocial,
      referralInfo,
      ...signUpData
    } = yield select((state) => state.user.signup)

    const userContactOptions = ['EMAIL', 'SMS']
    if (permissionPhone) {
      userContactOptions.push('PHONE')
    }
    if (permissionSocial) {
      userContactOptions.push('SOCIAL_MEDIA')
    }
    if (!permissionPost) {
      userContactOptions.push('POST')
    }

    Object.keys(signUpData).forEach((field) => {
      if (signUpData[field] && typeof signUpData[field] === 'string') {
        signUpData[field] = signUpData[field].trim()
      }
    })

    const phone = getPhoneNumberObject(phoneNumber, phoneCountry)
    const payload = {
      origin: 'WEB',
      accounts: [{ accountType: 'REM_SENDER' }],
      roles: ['USER'],
      country: phoneCountry,
      phoneNumber: phone.number,
      userContactOptions,
      organizationId,
      referralCode: get(referralInfo, 'referralCode', null),
      ...signUpData,
    }
    const { data } = yield call(userService.signupUser, payload)

    const { navigation } = yield select(({ ui }) => ui)

    if (data.status === 'SUCCESS') {
      yield put(setLoginData({
        idNumber: get(signUpData, 'idNumber', ''),
        password: get(signUpData, 'password', ''),
        phoneCountry,
        phoneNumber,
      }))
      yield put(signupActions[SUCCESS]())
      navigation.navigate('/signup/success')

      if (window.indexedDB) {
        try {
          localStorage.setItem('signupDocsNotUploaded', JSON.stringify(true))
          const { upload } = yield select(({ user }) => user)
          const dbOpenRequest = window.indexedDB.open('stb-storage', 1)

          dbOpenRequest.onsuccess = function() {
            const db = dbOpenRequest.result
            const transaction = db.transaction('signup', 'readwrite')
            const signup = transaction.objectStore('signup')
            signup.add({ ...upload[UPLOAD_TYPES.ID], type: UPLOAD_TYPES.ID })
            signup.add({ ...upload[UPLOAD_TYPES.SELFIE], type: UPLOAD_TYPES.SELFIE })
          }
        } catch (err) {
          console.log(err)
        }
      }
    } else {
      throw new Error(data.message || 'Something wrong')
    }
  } catch (err) {
    yield put(signupActions[FAIL](err.message || 'Can not Sign Up'))
    yield put(openToast({ type: 'error', toastMessage: err.message || 'Can not Sign Up' }))
  }
}

function* handleUpload(images: { [type: string]: Blob }, userId: string) {
  let withoutErrors = true
  for (const type in images) {
    try {
      const { data } = yield call(userService.uploadDocument, {
        file: images[type],
        description: type,
        userId,
      })
      if (data.status !== 'SUCCESS') {
        throw new Error(data.message || `Can't upload ${type}`)
      }
      yield put(clearUploadFiles(type))
    } catch (err) {
      if (err.message) {
        yield put(openToast({ type: 'error', toastMessage: err.message }))
      }
      withoutErrors = false
    }
  }
  return withoutErrors
}

function* uploadDocuments(userId: string) {
  const { upload } = yield select(({ user }) => user)

  const signupDocsNotUploaded = localStorage.getItem('signupDocsNotUploaded')

  const images: { [type: string]: Blob } = {}

  if (!isEmpty(upload)) {
    for (const type in upload) {
      const blob: Blob = yield fetch(upload[type].image).then((res) => res.blob())
      const file = new File([blob], upload[type].name, { type: upload[type].mimeType })
      images[type] = file
    }
    yield handleUpload(images, userId)
  } else if (signupDocsNotUploaded && JSON.parse(signupDocsNotUploaded)) {
    if (window.indexedDB) {
      try {
        const dbOpenRequest = window.indexedDB.open('stb-storage', 1)
        let db: any = undefined
        let dbError = false

        dbOpenRequest.onerror = () => {
          dbError = true
        }

        dbOpenRequest.onsuccess = async () => {
          db = dbOpenRequest.result
          const transaction = db.transaction('signup', 'readonly')
          transaction.onerror = () => {
            dbError = true
          }
          const signup = transaction.objectStore('signup')
          const requestId = signup.get(UPLOAD_TYPES.ID)
          const requestSelfie = signup.get(UPLOAD_TYPES.SELFIE)

          requestId.onerror = () => {
            dbError = true
          }
          requestSelfie.onerror = () => {
            dbError = true
          }
          requestId.onsuccess = async function() {
            if (requestId.result && requestId.result.image) {
              const blob: Blob = await fetch(requestId.result.image).then((res) => res.blob())
              const file = new File([blob], requestId.result.name, { type: requestId.result.mimeType })
              images[UPLOAD_TYPES.ID] = file
            }
          }
          requestSelfie.onsuccess = async function() {
            if (requestSelfie.result && requestSelfie.result.image) {
              const blob: Blob = await fetch(requestSelfie.result.image).then((res) => res.blob())
              const file = new File([blob], requestSelfie.result.name, { type: requestSelfie.result.mimeType })
              images[UPLOAD_TYPES.SELFIE] = file
            }
          }
        }
        while (!dbError && (!images[UPLOAD_TYPES.ID] || !images[UPLOAD_TYPES.SELFIE])) {
          yield delay(50)
        }
        const withoutErrors: boolean = yield handleUpload(images, userId)
        if (!dbError && withoutErrors && !!db) {
          db.transaction('signup', 'readwrite')
            .objectStore('signup')
            .clear()
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
}

export function* getUserDocumentsSaga() {
  try {
    const { data } = yield userService.getUserDocumentsList()

    if (!data.userDocumentDetails.length) {
      return {}
    }
    const userDocuments = data.userDocumentDetails
      .reduce((acc: object, doc: IDocument) => ({
        ...acc, [doc.documentType]: {
          documentId: doc.documentId,
          documentType: doc.documentType,
        },
      }), {})

    yield put(setUserDocuments(userDocuments))

    return userDocuments
  } catch (error) {
    console.log(error)
  }
}

function* getContentDocuments(userDocumentDetails: { [type: string]: IDocument }, attempt: number = 0): any {
  let hasError: boolean = false
  const { documents } = yield select(({ user }) => user)

  for (const type in userDocumentDetails) {
    const currentDoc = userDocumentDetails[type]
    try {
      const { data: blob } = yield call(userService.getDocumentById, currentDoc.documentId)
      const base64Image = yield new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function() {
          if (reader.error) {
            reject(reader.error)
          }
          resolve(reader.result)
        }
        reader.readAsDataURL(blob)
      })

      yield put(
        setUserDocuments({
          [type]: {
            documentId: currentDoc.documentId,
            documentType: currentDoc.documentType,
            image: base64Image,
          },
        }),
      )
    } catch (err) {
      yield put(
        setUserDocuments({
          [type]: {
            documentId: currentDoc.documentId,
            documentType: currentDoc.documentType,
          },
        }),
      )
      hasError = true
      continue
    }
  }
  if (hasError && attempt < 3) {
    yield getContentDocuments(userDocumentDetails, attempt + 1)
  }
}

export function* editUserSaga({ payload }: PayloadAction<any>) {
  try {
    yield put(openSpinner())
    const { routeName, userEmail, ...requestData } = payload
    const { userId, userMeta } = yield select(({ user }) => user)
    const { navigation } = yield select(({ ui }) => ui)

    const { data: editedUser } = yield call(userService.editUser, {
      ...requestData,
      userId,
    })

    yield* updateUserDocumentsSaga()

    yield put(editUserActions[SUCCESS]({ ...userMeta, ...requestData }))

    if (routeName) {
      navigation.navigate(routeName)
    }

    const { complianceStatus } = yield select(({ user }) => user.userMeta)

    if (complianceStatus === 'ACTION_REQUIRED' && payload.address) {
      yield put(openModal({
        type: ActionSuccess,
        paperClass: 'wide-modal withoutPadding',
        props: { action: 'INPUT_ISRAEL_ADDRESS' },
      }))
    } else {
      yield put(openToast({ type: 'success', toastMessage: 'messages:profile_data_changed' }))
    }
  } catch (err) {
    yield put(editUserActions[FAIL](err.message))
    yield put(openToast({ type: 'error', toastMessage: err.message || 'errors:something_wrong' }))
  } finally {
    yield put(closeSpinner())
  }
}

export function* updateUserDocumentsSaga() {
  try {
    yield put(openSpinner())
    const { userId, upload } = yield select(({ user }) => user)

    if (!isEmpty(upload)) {
      const types = Object.keys(upload)
      yield* uploadDocuments(userId)

      if (types.includes('AVATAR')) {
        const documents: { [type: string]: IDocument } = yield getUserDocumentsSaga()
        const editedDocs: { [type: string]: IDocument } = {}
        types.forEach(type => {
          editedDocs[type] = documents[type]
        })
        yield call(getContentDocuments, editedDocs)
      }

      const { complianceStatus } = yield select(({ user }) => user.userMeta)
      const { activeComplianceRules, transferData } = yield select(({ transfer }) => transfer)

      if (types.includes(UPLOAD_TYPES.SECOND_ID) && !!activeComplianceRules.CHECK_DOSSIER?.SECOND_ID) {
        if (!activeComplianceRules.CHECK_DOSSIER?.KYC) {
          yield put(openModal({
            type: DossierSuccess,
            paperClass: 'wide-modal withoutPadding',
            props: { type: 'SECOND_ID', transferData },
          }))
        } else {
          const { navigation } = yield select(({ ui }) => ui)
          navigation.navigate('/kyc-questionnaire')
        }
      } else if (complianceStatus === 'ACTION_REQUIRED') {
        const actionMap: { [key: string]: string } = {
          SECOND_ID: 'SECOND_ID',
          PROOF_OF_INCOME: 'INCOME_PROOF',
          SELFIE: 'UPLOAD_SELFIE',
          ID: 'UPLOAD_ID',
        }
        yield put(openModal({
          type: ActionSuccess,
          paperClass: 'wide-modal withoutPadding',
          props: { action: actionMap[types[0]] },
        }))
        const { navigation } = yield select(({ ui }) => ui)
        navigation.navigate('/dashboard')
      }
    }
  } catch (err) {
    console.log(err.message)
  } finally {
    yield put(closeSpinner())
  }
}

export function* logoutSaga() {
  sessionStorage.removeItem('@AuthToken')
  const { navigation } = yield select(({ ui }) => ui)
  navigation.navigate('/start')
  removeAuthToken()

  const firebaseAuthToken = notificationService.getFirebaseAuthToken()
  yield call(userService.firebaseLogout, firebaseAuthToken)
}

function* sendMessageViaEmailSaga({ payload }: PayloadAction<string>) {
  try {
    const { userId } = yield select((state) => state.user)
    const request = {
      userId,
      supportMessage: payload,
    }
    yield call(userService.sendEmailMessage, request)
    yield put(closeModal())
    yield put(openToast({ type: 'success', toastMessage: 'settings:message_sent' }))
  } catch (err) {
    console.log(err)
    yield put(openToast({ type: 'error', toastMessage: 'errors:something_wrong' }))
  }
}

export function* changeUserSettingsSaga({ payload }: PayloadAction<IChangeSettingsRequest>) {
  try {
    yield put(openSpinner())
    const { oldPassword, newPassword, userContactOptionsList } = payload

    const { data: optionsData } = yield call(userService.saveUserContactOptions, { userContactOptionsList })
    if (optionsData.status === 'SUCCESS') {
      yield put(setContactOptions(optionsData.userContactOptionsList))
    } else {
      throw new Error(optionsData.message || '')
    }
    if (!!newPassword && !!oldPassword) {
      const {
        data: { status, message },
      } = yield call(userService.changePassword, { oldPassword, newPassword })
      if (status !== 'SUCCESS') {
        throw new Error(message || '')
      }
    }
    yield put(openToast({ type: 'success', toastMessage: 'messages:profile_data_changed' }))
  } catch (err) {
    console.log(err)
    yield put(openToast({ type: 'error', toastMessage: err.message || 'errors:something_wrong' }))
  } finally {
    yield put(closeSpinner())
  }
}

export function* validateNewPhoneSaga({ payload }: PayloadAction<{ phoneCountryCode: string, phoneNumber: string }>) {
  try {
    const { sessionId } = yield select(({ user }) => user.changePhoneNumber)
    const phone = getPhoneNumberObject(payload.phoneNumber, payload.phoneCountryCode || 'IL')
    const body = { sessionId, phoneCountryCode: payload.phoneCountryCode, phoneNumber: phone?.number }
    const { data: { status, message } } = yield call(userService.validateNewPhone, body)
    if (status !== 'SUCCESS') {
      throw new Error(message)
    }
    yield put(validateNewPhoneActions[SUCCESS](phone?.number as string))
    yield put(openModal({
      type: ConfirmPhone,
      paperClass: 'qr-code-paper-modal',
      props: { phoneNumber: phone?.number, newPhone: true },
    }))
  } catch (err) {
    yield put(validateNewPhoneActions[FAIL](err.message))
  }
}

export function* approveNewPhoneSaga({ payload }: PayloadAction<{ smsCode: string }>) {
  try {
    const { sessionId } = yield select(({ user }) => user.changePhoneNumber)
    const { data: { status, message, data } } = yield call(userService.approveNewPhone, { ...payload, sessionId })
    if (status !== 'SUCCESS') {
      throw new Error(message)
    }
    yield put(approveNewPhoneActions[SUCCESS](data))
    yield put(openModal({
      type: ChangePhoneSuccess,
      paperClass: 'wide-modal withoutPadding',
    }))
  } catch (err) {
    yield put(approveNewPhoneActions[FAIL](err.message))
  }
}

const getContactOptionsFork = fork(actionsHandler, {
  actions: getContactOptionsActions,
  method: 'POST',
  paramsGetter: function* getBody() {
    return { data: {} }
  },
  responseParser: (response: { data: { userContactOptionsList: string[] } }) => response.data.userContactOptionsList,
  url: GET_CONTACT_OPTIONS,
})

const createSessionChangePhoneFork = fork(actionsHandler, {
  actions: createSessionChangePhoneActions,
  method: 'POST',
  paramsGetter: () => ({ data: {} }),
  responseParser: (response: { data: { sessionId: string } }) => response.data.sessionId,
  url: '/mobile/user/change_phone/get_session',
})

const cancelChangePhoneFork = fork(actionsHandler, {
  actions: cancelChangePhoneActions,
  method: 'POST',
  paramsGetter: function* getBody(payload: { comment: string, commentCode: string }) {
    return { data: { ...payload } }
  },
  url: '/mobile/user/change_phone/new_phone_cancel',
})

export default function* rootSaga() {
  yield fork(watchServiceWorker)
  yield all<ForkEffect>([
    getContactOptionsFork,
    createSessionChangePhoneFork,
    cancelChangePhoneFork,
    yield takeEvery(loginActions[REQUEST].toString(), loginSaga),
    yield takeEvery(loginActions[SUCCESS].toString(), successLoginSaga),
    yield takeEvery(signupActions[REQUEST].toString(), signUpSaga),
    yield takeEvery(editUserActions[REQUEST].toString(), editUserSaga),
    yield takeEvery(checkAccount.toString(), checkAccountSaga),
    yield takeEvery(sendNewPasswordActions[REQUEST].toString(), sendNewPasswordSaga),
    yield takeEvery(getLinkResetPassword.toString(), getLinkResetPasswordSaga),
    yield takeEvery(sendVerificationCode.toString(), sendVerificationCodeSaga),
    yield takeEvery(updateDocuments.toString(), updateUserDocumentsSaga),
    yield takeEvery(sendEmailMessage.toString(), sendMessageViaEmailSaga),
    yield takeEvery(logout.toString(), logoutSaga),
    yield takeEvery(changeSettings.toString(), changeUserSettingsSaga),
    yield takeEvery(getNotificationsRequest.toString(), getNotificationsSaga),
    yield takeEvery(deleteNotificationsActions[REQUEST].toString(), deleteNotificationsSaga),
    yield takeEvery(validateNewPhoneActions[REQUEST].toString(), validateNewPhoneSaga),
    yield takeEvery(approveNewPhoneActions[REQUEST].toString(), approveNewPhoneSaga),
  ])
}
